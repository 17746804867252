<template>
	<v-container fluid class="px-10">
		<v-row>
			<v-col cols="12">
				<h1 color="white">Team</h1>
				<v-divider class="my-3"></v-divider>
			</v-col>

			<v-col cols="12" v-if="teamLoading">
				<v-row>
					<v-col cols="12" v-for="(item, index) in placeholder" :key="index">
						<v-skeleton-loader height="135" class="mx-auto rounded-xl" type="card"></v-skeleton-loader>
					</v-col>
				</v-row>
			</v-col>

			<v-col md="6" sm="12" lg="4" xl="4" cols="12" v-if="team.message">
				<h4>{{ team.message }}</h4>
			</v-col>

			<v-col v-else-if="!teamLoading && !team.message" cols="12" v-for="streamer in team" :key="streamer.id" >
				<v-card 
					flat 
					tile
					height="135"
					class="rounded-xl pa-0 fill-height mgn_card"
					target="_blank"
					:href="`https://www.twitch.tv/${streamer.login}`"
					:img="streamer.is_live ? `https://static-cdn.jtvnw.net/previews-ttv/live_user_${streamer.login}-1920x1080.jpg` : streamer.offline_image_url" 
					v-bind:class="{ 'is_live': streamer.is_live }"
				>
					<v-card-title>
						<v-img 
							max-width="85" 
							class="profile_pic" 
							v-bind:class="{ 'is_live': streamer.is_live }"
							:src="streamer.profile_image_url"
							transition="scale-transition">
						</v-img>
						<h3>{{ streamer.display_name }}</h3>
					</v-card-title>
				</v-card>
			</v-col>

			<v-col v-else-if="error">
				<h2>Error loading streamers. :(</h2>
			</v-col>

		</v-row>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			placeholder: 4,
			team: [],
			teamLoading: true,
			error: false,
		}
	},

	async created() {
		await this.fetchCore();
	},

	methods: {
		async fetchCore () {
			try {
				const request = await fetch(`${this.$store.state.base_url}/team`, {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});
				
				const res = await request.json();

				if (request.ok) {
					if (res.streams) {
						this.team = res.streams;
					}
					else {
						this.team = res;
					}

					this.teamLoading = false;
				}
				
				if (request.status == 500 || request.status == 401) {
					if (res.error && this.$store.state.error_types.includes(res.error)) {
						this.sessionExpiredHandler(res);
						return;
					}

					throw (res);
				}
			} 
			catch (error) {
				this.error = true;
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message === 'Failed to fetch' ? 'A network error occured. Try again later.' : error.message
				});
			}
		},
	},
}
</script>

<style scoped>
.is_live {
	border: 5px solid #19CA6E;
	box-shadow: 5px solid #19CA6E;
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(25, 202, 110, 0.7);
	}

	70% {
		box-shadow: 0 0 0 15px rgba(25, 202, 110, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(25, 202, 110, 0);
	}
}
</style>